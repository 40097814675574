export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const ImageIcons = require("../assets/addImage.png");
export const boxIcons = require("../assets/home_new.png");
export const filterIcons = require("../assets/filter_new.png");
export const Profile = require("../assets/profile.png");
export const SOSIcon = require("../assets/SOSIcon.png");
export const searchIcons = require("../assets/searchIcon.png");
export const bannerImg = require("../assets/bannerImg.png");
export const rating = require("../assets/starGreen.png");
export const location = require("../assets/location.png");
export const ReviewRating = require("../assets/view_Star.png");
export const StartGray = require("../assets/StartGray.png");
export const Calender = require("../assets/calender.png");
export const NoData = require('../assets/closeBtn.png');
export const NurseImg = require("../assets/nurse_image.svg");
export const DownArrow = require("../assets/down-arrow.png");
export const UpwardArrow  = require("../assets/upward-arrow.png");
export const UserProfile  = require("../assets/user_icon.png");
export const ProfilePicTwo  = require("../assets/pic_two.png");
export const ProfilePicOne  = require("../assets/pic_one.png");
export const NurseBanner  = require("../assets/sample_banner.png");
export const AyahBannner = require("../assets/ayah_banner.png");
export const DoctorBanner = require("../assets/docProfile.png");
export const LeftArrow = require("../assets/left-arrow.png");
export const RightArrow  = require("../assets/right-arrow.png");