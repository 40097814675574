import React from "react";
// Customizable Area Start
import Reservation2Controller, { Props } from "./Reservations2Controller";
import { withStyles, CardContent, Typography, Grid, Box, Avatar, Button, ThemeProvider, createTheme } from "@material-ui/core";
import Sidebar from "../../../components/src/Sidebar.web";
import { FlexDirectionProperty } from "./types";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { ProfilePicOne, UserProfile } from "./assets";
import StarIcon from '@material-ui/icons/Star';


const theme = createTheme({
    palette: {
      primary: {
        contrastText: "#fff",
        main: "#0000ff",
      }
    }
  });

  const reviewers = [
    {
      image: ProfilePicOne || UserProfile,
      name: 'Lucas Simoes',
      description: 'Conan Matusav is a Registered Charity under the Charities Acts.',
      rating: 4.2,
    },
  ];

// Customizable Area End
export class Reservations2NurseProfile extends Reservation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

    async componentDidMount() {
        await this.getProfileHPData();
    }

    RenderProfileInfo = (data: any) => {
        return (
            <Box style={webStyles.profileInfo}>
                <Avatar alt="Lucas Simoes" src={data?.profile_image_url?.url || UserProfile} style={webStyles.avatar} />
                <Box>
                    <Typography style={webStyles.nurseProfileName} variant="h5">{data?.name || "N/A"}</Typography>
                    <Typography style={webStyles.nurseProfileDesc} color="textSecondary">{data?.address?.data?.attributes?.city || "N/A"}</Typography>
                </Box>
            </Box>
        )
    }

    RenderDescription = (descData: any) => {
        if (descData?.profile_type === 'ayah') {
            return (
              <>
                {descData?.gender === "Male" ? "Mr." : "Ms."} {descData?.name || "Chinaza Akachi"} is a verified ayah. 
                She has been tirelessly delivering unmatched care and comfort to patients since the last {descData?.experience || 0} years.
              </>
            );
          }
        if (descData?.profile_type === 'doctor') {
        return (
            <>
            {descData?.gender === "Male" ? "Mr." : "Ms."} {descData?.name} is a verified doctor. 
            She has passed {descData?.educational_degrees[descData?.educational_degrees.length - 1].degree} from {descData?.educational_degrees[descData?.educational_degrees.length - 1].college}. She has {descData?.experience} years of experience working as a doctor.
            </>
        );
        }
        return (
            <>
               {this.renderNurseDescription(descData)}
            </>
          );
    }

    renderNurseDescription = (descData: any) => {
        return (
            <>
                {descData?.gender === "Male" ? "Mr." : "Ms."} {descData?.name || "Lucas Simoes"} is a verified nurse. {" "}
                {descData?.educational_degrees && descData.educational_degrees.some((degree: any) => degree.degree.trim() !== "") && (
                    <>
                        {descData?.educational_degrees.filter((degree: any) => degree.degree.trim() !== "").map((degree: any, index: number) => (
                            <React.Fragment key={degree.id}>
                                {index > 0 && ', '}
                                {index === 0 && "She has passed "}{degree.degree} {degree.college ? ` from ${degree.college}` : ""}
                            </React.Fragment>
                        ))}
                        .
                    </>
                )}
                She has {descData?.experience || "0"} years of experience working as a nurse.
            </>
        )
    }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <>
            <ThemeProvider theme={theme}>
                <Grid container style={webStyles.profileContainer}>
                    <Grid item xs={1}>
                        <Sidebar />
                    </Grid>
                    <Grid item xs={11}>
                        <Box style={webStyles.availbleProfileContainer}>
                            <Box style={webStyles.ProfileHeaderContainer}>
                                <Box style={webStyles.ProfileHeading}>
                                    <ArrowBackIosIcon
                                        style={webStyles.ProfileBackButton}
                                        data-test-id="goToViewNursesElem"
                                        onClick={() => this.goToViewNurses(this?.state?.nurseData?.attributes?.profile_type)}
                                    />
                                    <Typography variant="h6" style={webStyles.ProfileTitle}>
                                        Profile
                                    </Typography>
                                </Box>
                            </Box>
                            <Box style={webStyles.nurseProfileMainBox}>
                                {this.RenderProfileInfo(this?.state?.nurseData?.attributes)}
                                <Box>
                                    <Button
                                        variant="contained"
                                        data-test-id={`bookNow`}
                                        color="primary"
                                        style={webStyles.BookNowBtn}
                                        onClick={() => this.goToBookNurseNow(this.props.navigation.getParam("navigationBarTitleText"),this?.state?.nurseData?.attributes?.profile_type)}
                                    >
                                        Book Now
                                    </Button>
                                </Box>
                            </Box>

                            <Box>
                                <Typography style={webStyles.descriptionTitle}>Description</Typography>
                                <Typography style={webStyles.descriptionContent}>
                                    {this.RenderDescription(this?.state?.nurseData?.attributes)}
                                </Typography>
                                <Box style={webStyles.nurseProfileDivider}></Box>

                                <Grid container style={webStyles.detailContainer} spacing={2}>
                                    <Grid item xs={12} style={webStyles.detailItemStyle}>
                                        <div style={webStyles.labelStyle}>
                                            <Typography variant="body2" style={webStyles.labelTextStyle}>Age</Typography>
                                            <Typography variant="body2" style={webStyles.colonStyle}>:</Typography>
                                        </div>
                                        <Typography style={webStyles.detailsValue} variant="body2">{this?.state?.nurseData?.attributes?.age ? `${this?.state?.nurseData?.attributes?.age} y/o` : "N/A"}</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={webStyles.detailItemStyle}>
                                        <div style={webStyles.labelStyle}>
                                            <Typography variant="body2" style={webStyles.labelTextStyle}>Gender</Typography>
                                            <Typography variant="body2" style={webStyles.colonStyle}>:</Typography>
                                        </div>
                                        <Typography style={webStyles.detailsValue} variant="body2">{this?.state?.nurseData?.attributes?.gender || "N/A"}</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={webStyles.detailItemStyle}>
                                        <div style={webStyles.labelStyle}>
                                            <Typography variant="body2" style={webStyles.labelTextStyle}>{this?.state?.nurseData?.attributes?.profile_type === "doctor" ? "Department" : "Working Shifts"}</Typography>
                                            <Typography variant="body2" style={webStyles.colonStyle}>:</Typography>
                                        </div>
                                        {this?.state?.nurseData?.attributes?.profile_type === "doctor" ? (
                                            <>
                                                <Typography style={webStyles.detailsValue} variant="body2">{this.state?.nurseData?.attributes?.profile_type_specific_data?.department?.name}</Typography>
                                            </>
                                        ) : (
                                            <>
                                                <Typography style={webStyles.detailsValue} variant="body2">
                                                    {this.formatShiftOutput(this?.state?.nurseData?.attributes?.working_shifts, this?.state?.nurseData?.attributes?.profile_type) || "N/A"}
                                                </Typography>
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} style={webStyles.detailItemStyle}>
                                        <div style={webStyles.labelStyle}>
                                            <Typography variant="body2" style={webStyles.labelTextStyle}>Experience</Typography>
                                            <Typography variant="body2" style={webStyles.colonStyle}>:</Typography>
                                        </div>
                                        <Typography style={webStyles.detailsValue} variant="body2">{this?.state?.nurseData?.attributes?.experience ? `${this?.state?.nurseData?.attributes?.experience} Years` : "N/A"}</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={webStyles.detailItemStyle}>
                                        <div style={webStyles.labelStyle}>
                                            <Typography variant="body2" style={webStyles.labelTextStyle}>Language Known</Typography>
                                            <Typography variant="body2" style={webStyles.colonStyle}>:</Typography>
                                        </div>
                                        <Typography style={webStyles.detailsValue} variant="body2">
                                            {this?.state?.nurseData?.attributes?.language_attributes?.length > 0 ?
                                            this?.state?.nurseData?.attributes?.language_attributes?.map((elem: any) => elem.name).join(", ")
                                            : "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={webStyles.detailItemStyle}>
                                        <div style={webStyles.labelStyle}>
                                            <Typography variant="body2" style={webStyles.labelTextStyle}>Email Address</Typography>
                                            <Typography variant="body2" style={webStyles.colonStyle}>:</Typography>
                                        </div>
                                        <Typography style={webStyles.detailsValue} variant="body2">{this?.state?.nurseData?.attributes?.email || "N/A"}</Typography>
                                    </Grid>
                                </Grid>

                                <Box style={webStyles.nurseProfileDivider}></Box>
                                <Box style={webStyles.reviewContainer}>
                                    <Box style={webStyles.reviewHeader}>
                                        <Typography variant="h6" style={webStyles.reviewTitle}>
                                            Reviews
                                        </Typography>
                                        <Typography data-test-id="navigateToNurseReviewsSpan" onClick={() => this.navigateToNurseReviews(this.props.navigation.getParam("navigationBarTitleText"))} variant="h6" style={webStyles.seeAllReview}>
                                            See All (402)
                                        </Typography>
                                    </Box>
                                    {reviewers?.length > 0 ? reviewers.map((user: any, index: string | number) => (
                                        <Box data-test-id="reviewerInfoCardDiv" key={index} style={webStyles.reviewerFlexBox}>
                                            <img style={webStyles.reviewerCover} src={user.image} title={user.name} />
                                            <CardContent style={webStyles.reviewerContent}>
                                                <div>
                                                    <Typography style={webStyles.reviewerName} component="h5" variant="h5">
                                                        {user.name}
                                                    </Typography>
                                                    <Typography style={webStyles.reviewerDescription} component="h5" variant="h5">
                                                        {user.description}
                                                    </Typography>
                                                </div>
                                                <div style={webStyles.reviewerOverallRating}>
                                                    <StarIcon />
                                                    <Typography variant="body2" style={{ marginLeft: '4px' }}>
                                                        <span style={webStyles.reviewerRating}>{user.rating || "4.2"}</span><span style={webStyles.reviewerRatingOutOf}> / 5 </span>
                                                    </Typography>
                                                </div>
                                            </CardContent>
                                        </Box>
                                    )) : (
                                        <Box id="noReviewsFound" style={webStyles.noReviewsFound}>No reviewers data available.</Box>
                                    )}
                                    <Box style={webStyles.reviewDivider}></Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
    profileContainer: {
        display: "flex",
    },
    availbleProfileContainer: {
        margin: "40px 50px",
        width: "70%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column" as FlexDirectionProperty,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "20px",
    },
    noReviewsFound: {
        fontSize: "20px",
        fontFamily: "SF Pro Text Medium",
        fontWeight: 500,
        color: "#808080",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        margin: "60px auto 30px",
    },
    ProfileHeaderContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: "25px",
        justifyContent: "space-between",
    },
    ProfileHeading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "25px",
    },
    ProfileBackButton: {
        display: "flex",
        alignItems: "center",
        fontSize: "22px",
        justifyContent: "center",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
        padding: "12px 8px 12px 16px",
    },
    ProfileTitle: {
        fontFamily: "SF Pro Text Bold",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "33.41px",
    },
    avatar: {
        width: "138px",
        height: "138px",
        margin: 'auto',
    },
    nurseProfileMainBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: "100%",
        margin: "10px 0 15px"
    },
    nurseProfileName: {
        fontFamily: 'SF Pro Text Bold',
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '28.64px',
        color: '#292929',
        textTransform: 'capitalize' as 'capitalize',
    },
    nurseProfileDesc: {
        fontFamily: 'SF Pro Text Regular',
        fontSize: '16px',
        fontWeight: 400,
        color: '#808080',
        marginTop: "4px",
        textTransform: "capitalize" as "capitalize",
    },
    descriptionTitle: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '23.87px',
        color: '#000000',
        marginBottom: "15px"
    },
    descriptionContent: {
        fontFamily: 'SF Pro Text Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'justify' as 'justify',
        color: '#717171',
        marginBottom: "45px"
    },
    nurseProfileDivider: {
        width: '370px',
        height: '1px',
        opacity: 0.38,
        borderBottom: '1px solid #C2C2C2',
        margin: '20px 0px',
    },
    profileInfo: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '20px'
    },
    BookNowBtn: {
        caretColor: "transparent",
        backgroundColor: "#014866",
        color: "#fff",
        border: "none",
        borderRadius: "8px",
        width: "200px",
        height: "56px",
        cursor: "pointer",
        fontSize: "18px",
        fontFamily: "SF Pro Text Bold",
        fontWeight: 600,
        "&:hover": {
            backgroundColor: "#014866",
            color: "#fff"
        },
        textTransform: "capitalize" as "capitalize",
        float: "right" as "right",
        '@media (max-width:1250px)': {
            width: "160px !important",
        },
    },
    detailContainer: {
        maxWidth: "575px",
        padding: "15px 0px 5px"
    },
    detailsValue: {
        textAlign: 'left' as 'left',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '21.48px',
        color: '#808080',
    },
    detailItemStyle: {
        marginBottom: '8px',
        display: 'flex',
        gap: "40px"
    },
    labelStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '200px',
    },
    labelTextStyle: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '21.48px',
        color: '#292929',
        flex: 1,
        textAlign: 'left' as 'left',
        minWidth: "195px"
    },
    colonStyle: {
        textAlign: 'right' as 'right',
    },
    reviewContainer: {
        width: "80%",
    },
    reviewDivider: {
        width: '100%',
        height: '1px',
        opacity: 0.38,
        borderBottom: '1px solid #C2C2C2',
        margin: '20px 0px',
    },
    reviewHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    reviewTitle: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '23.87px',
        color: '#292929',
    },
    seeAllReview: {
        color: '#3780E0',
        fontFamily: 'SF Pro Text Medium',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.71px',
        cursor: "pointer"
    },
    reviewerFlexBox: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        gap: "20px",
        margin: "30px 0px"
    },
    reviewerCover: {
        width: "65px",
        height: "65px",
        borderRadius: '50%',
    },
    reviewerName: {
        fontFamily: 'SF Pro Text Bold',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '21.48px',
        letterSpacing: '-0.4341176450252533px',
        color: '#292929',
        marginBottom: '5px'
    },
    reviewerDescription: {
        fontFamily: 'SF Pro Text Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '-0.38588234782218933px',
        color: '#808080',
        width: '277px'
    },
    reviewerContent: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%",
        padding: 0,
    },
    reviewerRating: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.71px',
        letterSpacing: '-0.02800000086426735px',
        color: '#014866',
        marginLeft: '5px',
    },
    reviewerRatingOutOf: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.71px',
        letterSpacing: '-0.02800000086426735px',
        color: '#3780E0',
    },
    reviewerOverallRating: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "flex-end",
        color: '#4CAF50',
        fontWeight: 600,
    },
};

export default withStyles(webStyles)(Reservations2NurseProfile);
// Customizable Area End