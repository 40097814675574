import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { getStorageData } from "../../../framework/src/Utilities";


type leave =  [
  {
      id: number,
      date: string
  }
]

export const dayNames = {
  "M": "monday",
  "T": "tuesday",
  "W": "wednesday",
  "TH": "thursday",
  "F": "friday",
  "S": "saturday",
  "SUN": "sunday",
};

export const shiftsData = [
  {
    idx: 0,
    title: 'Shift 01',
    shift_title: 'shift_1',
    start_time: '9:00 AM',
    end_time: '3:00 PM',
    checked: true,
    days: [
      { day: "S", dayFull: dayNames.SUN, selected: false },
      { day: "M", dayFull: dayNames.M, selected: true },
      { day: "T", dayFull: dayNames.T, selected: false },
      { day: "W", dayFull: dayNames.W, selected: true },
      { day: "T", dayFull: dayNames.TH, selected: false },
      { day: "F", dayFull: dayNames.F, selected: false },
      { day: "S", dayFull: dayNames.S, selected: false },

    ],
  },
  {
    idx: 1,
    title: 'Shift 02',
    shift_title: 'shift_2',
    start_time: '3:00 PM',
    end_time: '9:00 PM',
    checked: true,
    days: [
      { day: "S", dayFull: dayNames.SUN, selected: true },
      { day: "M", dayFull: dayNames.M, selected: false },
      { day: "T", dayFull: dayNames.T, selected: false },
      { day: "W", dayFull: dayNames.W, selected: true },
      { day: "T", dayFull: dayNames.TH, selected: false },
      { day: "F", dayFull: dayNames.F, selected: true },
      { day: "S", dayFull: dayNames.S, selected: true },

    ],
  },
  {
    idx: 2,
    title: 'Shift 03',
    shift_title: 'shift_3',
    start_time: '9:00 PM',
    end_time: '9:00 AM',
    checked: false,
    days: [
      { day: "S", dayFull: dayNames.SUN, selected: false },
      { day: "M", dayFull: dayNames.M, selected: true },
      { day: "T", dayFull: dayNames.T, selected: true },
      { day: "W", dayFull: dayNames.W, selected: false },
      { day: "T", dayFull: dayNames.TH, selected: false },
      { day: "F", dayFull: dayNames.F, selected: true },
      { day: "S", dayFull: dayNames.S, selected: true },
    ],
  },
];

export const ayahShiftsData = [
  {
    title: 'Shift 01',
    shift_title: 'shift_1',
    start_time: '8:00 AM',
    end_time: '8:00 PM',
    days: [
      { day: "S", dayFull: dayNames.SUN, selected: true },
      { day: "M", dayFull: dayNames.M, selected: true },
      { day: "T", dayFull: dayNames.T, selected: false },
      { day: "W", dayFull: dayNames.W, selected: false },
      { day: "T", dayFull: dayNames.TH, selected: false },
      { day: "F", dayFull: dayNames.F, selected: true },
      { day: "S", dayFull: dayNames.S, selected: true },
    ],
  },
  {
    title: 'Shift 02',
    shift_title: 'shift_2',
    start_time: '8:00 PM',
    end_time: '8:00 AM',
    days: [
      { day: "S", dayFull: dayNames.SUN, selected: true },
      { day: "M", dayFull: dayNames.M, selected: false },
      { day: "T", dayFull: dayNames.T, selected: true },
      { day: "W", dayFull: dayNames.W, selected: true },
      { day: "T", dayFull: dayNames.TH, selected: true },
      { day: "F", dayFull: dayNames.F, selected: true },
      { day: "S", dayFull: dayNames.S, selected: true },
    ],
  },
];


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTime: number;
  selectedDate: Date | string | null;
  timeSlots: object[];
  serviceProviderId: string;
  serviceProviderSchedule: any;
  details: any;
  token: any;
  checked: any;
  leaveToggle: boolean;
  kind: string;
  isEdit: boolean;
  payloadData: unknown;
  errorMessage: string;
  successMessage: string;
  disableConfirm: boolean;
  loading: boolean;
  date: Date;
  selectedSlots: string[];
  showSlot: boolean;
  showSlotCancelPopup: boolean;
  cancelSlot: string[];
  cancelAllSlot: boolean;
  removeSlot: boolean;
  showSuccessMsg: boolean;
  slot: string;
  isLeave: boolean;
  isLeaveConfirmation: boolean;
  avaibilityPopup: boolean;
  isFullDay: boolean;
  isRecurrence: boolean;
  selectedType: string;
  isOpen: boolean;
  endType: boolean;
  recurringType: string;
  isMonthly: boolean;
  selectedMonthType: string;
  isRepeat: boolean;
  firstSlotTime:string[];
  slotDiff:number;
  firstStartDate:string;
  firstEndDate:string;
  dateError:string;
  start_time:string;
  end_time:string;
  title:string;
  selectedCustomType: string;
  secondSlotTime: string[];
  secondStartDate:string;
  secondEndDate:string;
  firstShiftMedicAvailabilityId : null | number;
  secondShiftMedicAvailabilityId : null | number;
  isAddTitle: boolean;
  shiftTime: string;
  customDate: Date;
  customEndType:string;
  activeDays:string[];
  isOccurrence:boolean;
  occurrenceCount: number;
  showDropdown: boolean;
  leaveDetails: leave | [];
  firstSlotCustomMedicAvailability:any;
  secondSlotCustomMedicAvailability:any;
  isUpdatedId: string;
  error:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
  
  // Customizable Area Start
  serviceProviderDetailApiId: any;
  serviceProviderScheduleApiId: any;
  getAvailabilityList: string = "";
  UpdateShiftAvailability: string = "";
  UpdateLeave: string = "";
  getHealthcarePersonnelAvailabilitiesAPiId: string = "";
  setCustomAvailabilitiesAPiId: string = "";
  applyLeaveAPiId: string = "";
  cancelLeaveAPIId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      selectedTime: 0,
      selectedDate: moment().format("dddd, D MMMM YYYY"),
      timeSlots: [],
      serviceProviderId: "",
      serviceProviderSchedule: "",
      details: {},
      token: null,
      successMessage: "",
      errorMessage: "",
      checked: shiftsData.map((shift: any) => ({
        idx: shift.idx,
        checked: true,
        start_time: shift.start_time,
        end_time: shift.end_time,
        shift_title: shift.shift_title,
        days: shift.days.map((day: { day: string; dayFull: string; selected: boolean }) => ({ day: day.day, dayFull: day.dayFull, selected: false }))
      })),
      kind: "nurse",
      leaveToggle: false,
      isEdit: false,
      payloadData: [],
      disableConfirm: true,
      loading: false,
      date: new Date(),
      selectedSlots: [],
      showSlot: false,
      showSlotCancelPopup: false,
      cancelSlot: [],
      cancelAllSlot: false,
      removeSlot: false,
      showSuccessMsg: false,
      slot: "",
      isLeave: false,
      isLeaveConfirmation: false,
      avaibilityPopup: false,
      isFullDay: false,
      isRecurrence: false,
      selectedType: "Does not repeat",
      isOpen: false,
      endType: false,
      recurringType: "Monthly",
      isMonthly: false,
      selectedMonthType: "",
      isRepeat: false,
      firstSlotTime: [],
      slotDiff:30,
      firstStartDate:"",
      firstEndDate:"",
      dateError:"",
      start_time:"",
      end_time:"",
      title:"",
      selectedCustomType:"",
      secondSlotTime: [],
      secondStartDate:"",
      secondEndDate:"",
      firstShiftMedicAvailabilityId : null,
      secondShiftMedicAvailabilityId : null,
      isAddTitle: false,
      shiftTime: "",
      customDate:new Date(),
      customEndType:"",
      activeDays:[],
      isOccurrence:false,
      occurrenceCount: 1,
      showDropdown: false,
      leaveDetails:[],
      firstSlotCustomMedicAvailability:[],
      secondSlotCustomMedicAvailability:[],
      isUpdatedId:"",
      error:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getServiceProviderDetails({
        setApiCallId: 'serviceProviderScheduleApiId',
        serviceProviderId: this.state.serviceProviderId,
        availableDate: this.state.selectedDate,
        token
      })
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        switch (apiRequestCallId) {
          case this.getAvailabilityList:
            this.setInitialAvailabilityData(responseJson);
            break;
          case this.UpdateLeave:
            this.setState({
              errorMessage: responseJson.errors ? responseJson.errors.message : "",
              successMessage: responseJson.meta ? responseJson.meta.message : ""
            })
            this.toggleLeave();
            this.GetAvailability();
            break;
          case this.UpdateShiftAvailability:
            this.setState({
              loading: false,
              errorMessage: responseJson.errors ? responseJson.errors.message : "",
              successMessage: responseJson.meta ? responseJson.meta.message : ""
            })
            this.GetAvailability();
            break;
          case this.getHealthcarePersonnelAvailabilitiesAPiId:
            this.getAvaibilityResponse(responseJson)
            break;
          case this.setCustomAvailabilitiesAPiId:
            this.setCustomAvaibilityResponse(responseJson)
            break;  
          case this.applyLeaveAPiId:
            this.responseLeaveApplied(responseJson);
            break; 
          case this.cancelLeaveAPIId:
            this.responseCancelLeave(responseJson);
            break;    
        }
      }

      if (apiRequestCallId != null) {

        if (apiRequestCallId === this.serviceProviderScheduleApiId) {
          this.setState({
            serviceProviderSchedule: responseJson && responseJson.data ? responseJson.data : ''
          });
        }
        if (apiRequestCallId === this.serviceProviderDetailApiId) {
          let navigateVar = new Message(getName(MessageEnum.NavigationCalendarMessage));
          navigateVar.addData(
            getName(MessageEnum.CalendarProviderDetailsResponseMessage),
            responseJson
          );
          this.send(navigateVar);
          this.unsubscribeMessages();
          return;
        }
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      runEngine.debugLog("Availability Message Received", message);
      const serviceProviderIdMessage = message.getData(
        getName(MessageEnum.ServiceProviderIdMessage)
      );
      if (serviceProviderIdMessage) {
        this.setState({
          serviceProviderId: serviceProviderIdMessage
        });
      }

      const CalendarProviderDetailsApiMessage = message.getData(
        getName(MessageEnum.CalendarProviderDetailsApiMessage)
      );
      if (CalendarProviderDetailsApiMessage) {
        this.getServiceProviderDetails({ ...CalendarProviderDetailsApiMessage, setApiCallId: 'serviceProviderDetailApiId' });
        return;

      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getHpUserRole();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }

    this.GetAvailability();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getHpUserRole = async () => {
    const hpUserType = await getStorageData("hpUserRole");
    this.setState({
      kind: hpUserType.toLowerCase(), checked: (hpUserType.toLowerCase() === "ayah" ? ayahShiftsData : shiftsData).map((shift: any) => ({
        idx: shift.idx,
        checked: true,
        start_time: shift.start_time,
        end_time: shift.end_time,
        shift_title: shift.shift_title,
        days: shift.days.map((day: any) => ({ day: day.day, dayFull: day.dayFull, selected: false }))
      }))
    })
  }
  GetAvailability = async () => {
    const method = "GET";
    const token = await getStorageData("authToken") as string;
    const header = {
      "Content-Type": 'application/json',
      "token": token
    };
    const getAvailabilityListUrl = "/healthcare_personnel_availabilities";
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAvailabilityList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getAvailabilityListUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  UpdateLeaveAvailability = async () => {
    const method = "POST";
    const token = await getStorageData("authToken") as string;
    const header = {
      "Content-Type": 'application/json',
      "token": token
    };
    let updateLeaveBody = {
      data: {
        attributes: {
          on_leave: !this.state.leaveToggle
        }
      }
    }
    const updateLeaveUrl = "healthcare_personnel_availabilities/edit_leave";
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.UpdateLeave = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      updateLeaveUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(updateLeaveBody)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  UpdateShiftAvailabilityApi = async () => {
    const method = "POST";
    const token = await getStorageData("authToken") as string;
    const header = {
      "Content-Type": 'application/json',
      "token": token
    };
    this.setState({
      loading: true,
    })
    let httpsBody = {
      data: {
        attributes: {
          availabilities: this.state.checked.map((elem: any) => {
            return {
              ...(this.state.isEdit ? { id: elem.idx } : {}),
              start_time: elem.start_time,
              end_time: elem.end_time,
              monday: elem.days[1].selected,
              tuesday: elem.days[2].selected,
              wednesday: elem.days[3].selected,
              thursday: elem.days[4].selected,
              friday: elem.days[5].selected,
              saturday: elem.days[6].selected,
              sunday: elem.days[0].selected,
              shift_title: elem.shift_title,
              is_available: elem.checked
            }
          })
        }
      }
    }
    const updateShiftUrl = this.state.isEdit ? "/healthcare_personnel_availabilities/bulk_edit" : "/healthcare_personnel_availabilities";
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.UpdateShiftAvailability = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      updateShiftUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpsBody)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setInitialAvailabilityData = (responseJson: { data: any, meta: any }) => {
    if (responseJson) {
      if (responseJson.data.length > 0) {
        this.setState({
          isEdit: true,
          leaveToggle: responseJson.meta.on_leave,
          disableConfirm: true,
        })
        if (responseJson.meta.on_leave) {
          this.toggleShiftsBasedOnLeave();
        } else {
          this.setState({
            checked: responseJson.data.map((shift: any) => {
              return {
                idx: shift.id,
                checked: shift.attributes.is_available,
                start_time: shift.attributes.start_time,
                end_time: shift.attributes.end_time,
                shift_title: shift.attributes.shift_title,
                days: [
                  { day: "S", dayFull: 'sunday', selected: shift.attributes.sunday },
                  { day: "M", dayFull: 'monday', selected: shift.attributes.monday },
                  { day: "T", dayFull: 'tuesday', selected: shift.attributes.tuesday },
                  { day: "W", dayFull: 'wednesday', selected: shift.attributes.wednesday },
                  { day: "T", dayFull: 'thursday', selected: shift.attributes.thursday },
                  { day: "F", dayFull: 'friday', selected: shift.attributes.friday },
                  { day: "S", dayFull: 'saturday', selected: shift.attributes.saturday },
                ]
              }
            }),
          })
        }
      } else {
        this.setState({
          isEdit: false,
        })
      }
    }
  }

  onSelectDate = (selectedDateStr: string) => {
    const { details } = this.state;
    this.setState({
      selectedDate: selectedDateStr,
      timeSlots: [],
      selectedTime: 0
    });
  };

  handleConfirm = () => {
    this.UpdateShiftAvailabilityApi();
  };

  toggleShiftsCheck = (idx: number, checked: boolean) => {
    if (!this.state.leaveToggle) {
      this.setState((prevState) => {
        const updatedChecked = [...prevState.checked];
        updatedChecked[idx] = { ...updatedChecked[idx], checked };
        return { checked: updatedChecked, disableConfirm: false };
      });
    } else {
      this.setState({
        errorMessage: "Please disable the Leave button to proceed with managing shift availabilities.",
      })
    }
  };

  toggleDaySelection = (shiftIndex: number, dayIndex: number) => {
    this.setState((prevState: any) => {
      const updatedChecked = [...prevState.checked];
      if (updatedChecked[shiftIndex].checked && !this.state.leaveToggle) {
        updatedChecked[shiftIndex].days[dayIndex].selected = !updatedChecked[shiftIndex].days[dayIndex].selected;
      } else if (this.state.leaveToggle) {
        this.setState({
          errorMessage: `You have enabled "On Leave". To proceed, please disable leave toggle.`,
        })
      }
      else {
        this.setState({
          errorMessage: `You have disabled this shift. To proceed, please enable this shift.`,
        })
      }
      return { checked: updatedChecked, ...(updatedChecked[shiftIndex].checked && { disableConfirm: false }) };
    });
  };

  toggleLeave = () => {
    if (!this.state.leaveToggle) {
      const updatedChecked = this.state.checked.map((item: any) => ({
        ...item,
        checked: false
      }));
      this.setState({
        checked: updatedChecked
      })
    }

    this.setState({
      leaveToggle: !this.state.leaveToggle,
    });
  };

  toggleShiftsBasedOnLeave = () => {
    if (this.state.leaveToggle) {
      const updatedChecked = this.state.checked.map((item: any) => ({
        ...item,
        checked: false,
        days: item.days.map((day: any) => ({ ...day, selected: false }))
      }));

      this.setState({
        checked: updatedChecked,
        disableConfirm: true,
        errorMessage: '',
      });
    }
  }

  calendarProps = {
    minDate: moment(),
    onSelectDate: (selectedDate: string) => this.onSelectDate(selectedDate)
  };

  async getServiceProviderDetails(dataObj: any) {
    const { setApiCallId, serviceProviderId, availableDate, token } = dataObj;

    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      token,
    };

    this.apiCall({
      setApiCallId,
      header,
      method: configJSON.getApiMethodType,
      endPoint: `${configJSON.serviceProviderAPiEndPoint}?availability_date=${availableDate}&service_provider_id=${serviceProviderId}`,
      body: null
    });
    return true;
  }

  apiCall = async (data: any) => {

    const { setApiCallId, header, endPoint, method, body } = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (setApiCallId === 'serviceProviderDetailApiId') {
      this.serviceProviderDetailApiId = requestMessage.messageId;
    } else if (setApiCallId === 'serviceProviderScheduleApiId') {
      this.serviceProviderScheduleApiId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  unsubscribeMessages = () => {
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  };

  handleDateChange = (date: any) => {
    this.setState({ date, dateError: "" }, () => {
      const leaveExists = this.state.leaveDetails?.some((leave: { date: string; }) => leave.date === this.formatDateToYYYYMMDD(new Date(this.state.date)))
      if (leaveExists) {
        this.setState({ isLeave: true });
      }else{
        this.setState({ isLeave: false });
      }
    }
    );
  }

  handleCustomDateChange = (customDate: any) => {
    if(this.state.endType){
      this.setState({ customDate });
    }
  }

  handleDateFormatChange = (date: any) => {
    if (date) {
      const dateType = new Date(date)
      const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
      const formattedDate = dateType.toLocaleDateString('en-GB', options);
      return formattedDate;
    }
  }

  handleCustomDateFormatChange = (date: any) => {
    if (date) {
      const customDateType = new Date(date)
      const options: Intl.DateTimeFormatOptions = {
        weekday: 'short',
        year: 'numeric', 
        month: 'short',  
        day: 'numeric' 
      };
      const formattedDate = customDateType.toLocaleDateString('en-US', options);
      return formattedDate;
    }
  }

  handleSlotSelect = (slot: string, shiftTime: string) => {
    if (this.state.date) {
      this.apiHealthcarePersonnelAvailabilities()
      const formattedSelectedDate = this.formatDateToYYYYMMDD(new Date(this.state.date));   
      let filteredAvailability = [];

      if (shiftTime === "Morning") {
        filteredAvailability = this.state.firstSlotCustomMedicAvailability.filter(
          (availability: any) => availability.event_start_date === formattedSelectedDate
        );
      } else if (shiftTime === "Evening") {
        filteredAvailability = this.state.secondSlotCustomMedicAvailability.filter(
          (availability: any) => availability.event_start_date === formattedSelectedDate
        );
      } 

      const matchingAvailability = filteredAvailability.find((availability: any) => {
        return this.convertSlotTimeFormat(availability.slot_time) === slot;
      }
    );

      if (matchingAvailability) {
        this.handelMatchingAvailability(matchingAvailability,slot,shiftTime)
      } else if (this.state.selectedSlots.includes(slot)) {
        this.handleShowSlot();
      } else {
        this.setState({ slot }, () => {
          this.handelAvaibilityPopup(shiftTime);
        });
      }
    }
  };
  
  handelMatchingAvailability = (matchingAvailability:any,slot: string, shiftTime: string) => {
    this.setState({
      title:matchingAvailability.title,
      isFullDay:matchingAvailability.full_day,
      avaibilityPopup: !this.state.avaibilityPopup,
      isRecurrence: false,
      selectedType: this.getCustomType(matchingAvailability.event_type),
      isOpen: false,
      recurringType: matchingAvailability.custom_repeat_type  !== null ? matchingAvailability.custom_repeat_type.charAt(0).toUpperCase() + matchingAvailability.custom_repeat_type.slice(1).toLowerCase(): "",
      isMonthly: false,
      isRepeat: false,
      shiftTime,
      selectedCustomType:matchingAvailability.custom_repeat_type  !== null ? this.getRepeatCustomType(matchingAvailability.custom_repeat_type) : "",
      slot,
      isUpdatedId:JSON.stringify(matchingAvailability.id),
      customDate:matchingAvailability.custom_end_date ? new Date(matchingAvailability.custom_end_date) : new Date(),
      customEndType:matchingAvailability.custom_month_type,
      selectedMonthType:this.getMonthlyDescription(matchingAvailability.custom_month_type),
      date: new Date(matchingAvailability.event_start_date),
      isOccurrence: matchingAvailability.occurance_count ? true : false,
      occurrenceCount: matchingAvailability.occurance_count ? matchingAvailability.occurance_count : 1,
      activeDays: matchingAvailability.custom_week_days !== null ? matchingAvailability.custom_week_days : [],
      endType:matchingAvailability.custom_end_type === 'end_on_date'? true : false,
     })
  }

  getMonthlyDescription(type:string) {
    if (type === 'on_date') {
      return `Monthly on day ${new Date(this.state.date).getDate()}`;
    } else if (type === 'on_day') {
      return `Monthly on second ${this.formatDateToDayAbbreviation('day')}`;
    } else {
      return '';
    }
  }

  getRepeatCustomType(input: string): string {
    switch (input) {
      case "weekly":
        return "Every week";
      case "monthly":
        return "Every month";
      case "yearly":
        return "Every year";
      default:
        return ""; 
    }
  }

  getCustomType(input: string): string {
    switch (input) {
      case "does_not_repeat":
        return "Does not repeat";
      case "every_day":
        return "Every day";
      case "every_week":
        return "Every week";
      case "every_month":
        return "Every month";
      case "every_year":
        return "Every year";
      case "custom":
        return "Custom";  
      default:
        return ""; 
    }
  }

  convertSlotTimeFormat = (slotTime: string): string => {
    const [startTime, endTime] = slotTime.split(" - ");
  
    const formatTimes = (time: string, isEndTime: boolean): string => {
      const [hourMinute, period] = time.split(" ");
      const [hour, minute] = hourMinute.split(":");
  
      const formattedHour = parseInt(hour, 10);
      const formattedMinute = minute !== '00' ? `:${minute}` : '';
  
      let formattedStartHour : string | number = formattedHour;
      
    if(isEndTime || minute !== '00'){
      formattedStartHour =  (formattedHour < 10 ? `${formattedHour}` : formattedHour)
    }

      return isEndTime 
        ? `${formattedStartHour}${formattedMinute} ${period.toLowerCase()}`
        : `${formattedStartHour}${formattedMinute}`;
    };
  
    const formattedStartTime = formatTimes(startTime, false);
    const formattedEndTime = formatTimes(endTime, true);
  
    return `${formattedStartTime} - ${formattedEndTime}`;
  };
  

  handleShowSlot = () => {
    this.setState({ showSlot: true })
  };

  handleClose = () => {
    this.setState({ showSlot: false, showSlotCancelPopup: false, cancelAllSlot: false, removeSlot: false })
  };

  handelShowSlotCancelPopup = (slot: string) => {
    this.setState({ showSlotCancelPopup: true, slot })
  }

  handleCancelBooking = (slot: string) => {
    this.setState((prevState) => {
      const isSelected = prevState.cancelSlot.includes(slot);
      return {
        cancelSlot: isSelected
          ? prevState.cancelSlot.filter((selectedslot: string) => selectedslot !== slot)
          : [...prevState.cancelSlot, slot],
      };
    });
  };

  handelSelectedSlot = () => {
    this.setState({ cancelAllSlot: !this.state.cancelAllSlot })
  }

  handelCancelSlot = () => {
    this.setState({ showSlotCancelPopup: false, cancelAllSlot: false, showSlot: false })
  }

  handelRemoveSlot = () => {
    this.setState({ removeSlot: true })
  }

  handelCancelAllSlot = () => {
    this.setState({ showSlotCancelPopup: false, cancelAllSlot: false, showSlot: false, removeSlot: false })
  }

  handelShowSuccessMsg = () => {
    this.setState({ showSuccessMsg: !this.state.showSuccessMsg })
  }

  closeSlotCancellation = () => {
    this.setState({ showSlotCancelPopup: false, cancelAllSlot: false, showSlot: false, removeSlot: false, showSuccessMsg: false, selectedSlots: [], slot: "" })
  }

  handelOpenEdit = () => {
    this.setState({ isEdit: true })
  }

  handelLeave = () => {
    const { date, leaveDetails } = this.state; 
    if (date) {
      const matchingLeave = leaveDetails?.find(
        (leave) => {
          return leave.date === this.formatDateToYYYYMMDD(new Date(this.state.date))}
      );

      if (matchingLeave) {
        this.setState(
          { isLeave: false, isLeaveConfirmation: false, showSuccessMsg: false},
          () => {
            this.handelResetLeave(matchingLeave.id)
          }
        );
      } else {
        this.setState({ isLeave: true, isLeaveConfirmation: true, showSuccessMsg: false});
      }      
    }
  }

  handelPreviousPage = () => {
    if (this.state.isEdit) {
      this.setState({ isEdit: false })
    } else {
      this.props.navigation.goBack()
    }
  }

  handelLeaveConfirmation = () => {
    this.setState({ isLeaveConfirmation: !this.state.isLeaveConfirmation, showSuccessMsg: false, isLeave: false })
  }

  closeLeaveConfirmation = () => {
    this.applyLeave()
  }

  handelConfirmation = () => {
    this.setState({ showSuccessMsg: true, isLeaveConfirmation: true, isLeave: true })
  }

  handelAvaibilityPopup = (shiftTime:string) => {
    this.setState({
      avaibilityPopup: !this.state.avaibilityPopup,
      isFullDay: false,
      selectedMonthType: "",
      isRecurrence: false,
      selectedType: "Does not repeat",
      isOpen: false,
      endType: !this.state.endType,
      recurringType: "Monthly",
      isMonthly: false,
      isRepeat: false,
      shiftTime,
      selectedCustomType:"Every month",
      title:"",
      isAddTitle: false,
      error:"",
    })
  }

  handelIsFullDay = () => {
    this.setState({ isFullDay: !this.state.isFullDay })
  }

  handelIsRecurrence = () => {
    this.setState({ isRecurrence: !this.state.isRecurrence })
  }

  handelSelectedype = (selectedType: string, recurringType: string) => {
    if (selectedType === "Custom") {
      this.setState({ selectedType, isOpen: false, recurringType, isRecurrence: false, isRepeat: true },() =>{
      })
    } else {
      this.setState({ selectedType,isRecurrence: false })
    }
  }

  handelCustomSelectedype = (selectedCustomType: string, recurringType: string) => {
      this.setState({ selectedCustomType, isOpen: false ,recurringType,error:""})
  }

  handelUpdateSelectedType = () => {
    this.setState({ selectedType: "Does not repeat", endType: !this.state.endType, isRepeat: false, isRecurrence: true,selectedCustomType:"" })
  }

  handelSelectOption = () => {
    this.setState({ isOpen: !this.state.isOpen, endType: !this.state.endType })
  }

  handelEndType = () => {
    this.setState({
      endType: !this.state.endType
    },
      () => {
        if (this.state.endType === true) {
          this.setState({
            isOccurrence: false
          });
        }
      })
  }

  handelMonthlySelectOption = () => {
    this.setState({ isMonthly: !this.state.isMonthly })
  }

  handelselectedMonthType = (selectedMonthType: string, customEndType: string) => {
    this.setState({ selectedMonthType, isMonthly: !this.state.isMonthly, customEndType,error:''})
  }

  apiHealthcarePersonnelAvailabilities = async () => {
    this.setState({loading:true})
    const token = await getStorageData("authToken") as string;

    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHealthcarePersonnelAvailabilitiesAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.healthcarePersonnelAvailabilitiesAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  createTimeSlots = (start:string, end:string,slot: number) => {
    const slots = [];
    let startTime = new Date(`01/01/2000 ${start}`);
    const endTime = new Date(`01/01/2000 ${end}`);

    while (startTime < endTime) {
        const endTimeSlot = new Date(startTime);
        endTimeSlot.setUTCMinutes(startTime.getUTCMinutes() + slot);
        const startTimeFormatted = this.formatTime(startTime,false);
        const endTimeFormatted = this.formatTime(endTimeSlot, true);

        slots.push(`${startTimeFormatted} - ${endTimeFormatted}`);

        startTime = endTimeSlot;
    }

    return slots;
}

formatTime = (date:Date, includeAMPM = false) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = '';
    if (includeAMPM) {
        if (hours >= 12) {
            ampm = 'pm';
        } else {
            ampm = 'am';
        }
    }
    hours = hours % 12;
    hours = hours ? hours : 12;
    let minute = minutes < 10 ? '0' + minutes : minutes;

    if (minute === '00') {
        return `${hours} ${ampm}`.trim();
    }
    
    return `${hours}:${minutes} ${ampm}`.trim();
}

formatTimeWithoutMinutes = (date:Date) => {
    let hours = date.getHours();
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${hours}`;
}

formatTimeWithAMPM(date:Date) {
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    let minute = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minute + ' ' + ampm;
}

parseTime = (time: string): Date  => {
  const [hourPart, minutePart] = time.split(":");
  let hours = parseInt(hourPart, 10);
  let minutes = 0;
  let timePeriod;

  if (minutePart) {
    const [minute, period] = minutePart.split(" ");
    minutes = parseInt(minute, 10);
    timePeriod = period.toLowerCase();
  } else {
    const [hour, period] = hourPart.split(" ");
    hours = parseInt(hour, 10);
    timePeriod = period.toLowerCase();
  }

  if (timePeriod === "pm" && hours !== 12) {
    hours += 12;
  }
  if (timePeriod === "am" && hours === 12) {
    hours = 0;
  }

  return new Date(2000, 0, 1, hours, minutes); 
}

updateSlots = (slots: string[], period: "Morning" | "Evening", cutoff: string): string[] => {
  const cutoffTime = this.parseTime(cutoff); 

  return slots.filter((slot: string) => {
    const endTime = slot.split(" - ")[1]; 
    const endTimeDate = this.parseTime(endTime);

    if (period === "Morning") {
      return endTimeDate < cutoffTime;
    } else if (period === "Evening") {
      return endTimeDate >= cutoffTime;
    }

    return false;
  });
}
 
formatStartNewTime(startTime:string, shiftTime:string, isStart:boolean) {
  if(isStart){
    const [hours, minutes="00"] = startTime.split(':').map(Number);

    let formattedHours = hours;
  
    if (shiftTime === "Evening" || (shiftTime === "Morning" && (hours < 7 || hours >= 12))) {
      formattedHours = (hours % 12) + 12; 
    }
    return `${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
}

formatEndNewTime(startTime:string, shiftTime:string, isStart:boolean) {
  if(!isStart){
    const match = startTime.match(/^(\d{1,2}:\d{2}|\d{1,2})\s*(am|pm)?$/i);

    if (!match) {
      throw new Error("Invalid time format");
    }

    const [_, hourMin, period] = match; 
    let [hour, minute] = hourMin.includes(":") ? hourMin.split(":") : [hourMin, "00"];

    let hourNumber = parseInt(hour, 10);
    minute = minute ? minute.padStart(2, "0") : "00"; 

    const timePeriod = period ? period.toLowerCase() : "am";

    if (timePeriod === "pm" && hourNumber !== 12) {
      hourNumber += 12;
    } else if (timePeriod === "am" && hourNumber === 12) {
      hourNumber = 0;
    }

    return `${hourNumber.toString().padStart(2, "0")}:${minute}`;
  }
}

createCustomAvailabilities = () => {
  this.setState({ error: "" }, () => {

    if (this.state.title === '') {
      this.setState({ error: "Please enter title" });
      return;
    }

    if (this.state.selectedType === "Custom") {
      if (this.state.recurringType === "") {
        this.setState({ error: "Please select recurring type." });
        return;
      }

      if (this.state.recurringType === "Monthly" && this.state.customEndType === "") {
        this.setState({ error: "Please select monthly custom end type." });
        return;
      }

      if (this.state.recurringType === "Weekly" && this.state.activeDays.length < 1) {
        this.setState({ error: "Please select week days." });
        return;
      }
    }

    if (this.state.error === "") {
      this.callAPI();
    }
  });
};;

callAPI = async() => {
  const token = await getStorageData("authToken") as string;
  const [start, end] = this.state.slot.split(' - ');
  const start_time = this.formatStartNewTime(start,this.state.shiftTime,true);
  const end_time = this.formatEndNewTime(end,this.state.shiftTime,false);
  let medic_availability_id = null;
  if(this.state.shiftTime === "Morning"){
    medic_availability_id =  this.state.firstShiftMedicAvailabilityId
  }else {
    medic_availability_id =  this.state.secondShiftMedicAvailabilityId
  }
  
    const httpBody = {
      data: {
        attributes: {
          start_time,
          medic_availability_id,
          end_time,
          title: `${this.state.title}`,
          full_day: this.state.isFullDay,
          event_type: this.getRepeatType(this.state.selectedType),
          event_start_date: new Date(this.state.date).toISOString().split('T')[0],
          ...(this.state.selectedType === "Custom" && this.state.selectedCustomType !== "" && {
            "custom_repeat_type": this.state.recurringType.toLowerCase(),
            ...(this.state.recurringType === "Weekly" && {
              "custom_week_days": this.state.activeDays,
            }),
            ...(this.state.recurringType === "Monthly" && {
              "custom_month_type": this.state.customEndType,
            }),
            ...(this.state.isOccurrence && {
              "custom_end_type": "occurence",
              "occurance_count": this.state.occurrenceCount
            }),
            ...(this.state.endType && {
              "custom_end_type": "end_on_date",
              "custom_end_date": this.state.customDate,
            })
          })
        }
      }
    };

    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setCustomAvailabilitiesAPiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.isUpdatedId !== '' ? `${configJSON.updateCustomAvailability}/${parseInt(this.state.isUpdatedId)}` : configJSON.createCustomAvailability
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.isUpdatedId !== '' ? configJSON.patchAPiMethod : configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
}

getRepeatType(input: string): string {
  switch (input) {
    case "Does not repeat":
      return "does_not_repeat";
    case "Every day":
      return "every_day";
    case "Every week":
      return "every_week";
    case "Every month":
      return "every_month";
    case "Every year":
      return "every_year";
    case "Custom":
      return "custom";  
    default:
      return ""; 
  }
}

closePopup = () => {
  this.setState({dateError:"",errorMessage:"",successMessage:""})
}

formatTimeBasedOnTitle = (time: string) => {
  const sanitizedTime = time.replace(/(am|pm)/i, "").trim();
  const [hourStr, minuteStr = "00"] = sanitizedTime.split(":");
  let hour = parseInt(hourStr, 10);
  let minute = parseInt(minuteStr, 10);

  const formattedHour = hour.toString().padStart(2, "0");
  const formattedMinute = minute.toString().padStart(2, "0");

  if (this.state.title === "Morning") {
    if (hour < 12) {
      return `${formattedHour}:${formattedMinute} am`; 
    } else if (hour === 12 || (hour >= 12 && hour < 4 && (hour > 12 || (hour === 3 && minute <= 30)))) {
      return `${formattedHour}:${formattedMinute} pm`; 
    } else {
      return `${formattedHour}:${formattedMinute} am`; 
    }
  }

  return `${formattedHour}:${formattedMinute} pm`; 
};

formatDateToDDMMYYYY = (date: Date, type:string): string  => {
  const day: string = String(date.getDate()).padStart(2, '0');
  const month: string = String(date.getMonth() + 1).padStart(2, '0'); 
  const year: number = date.getFullYear();

  return `${day}${type}${month}${type}${year}`;
}

applyLeave = async () => {
  const token = await getStorageData("authToken") as string;
  const date= new Date(this.state.date);
  const formattedDate: string = this.formatDateToDDMMYYYY(date,"-");

  const httpBody = {
    data: {
        attributes: {
          date: formattedDate,
        }
    }
  };

  const header = {
    "Content-Type": configJSON.applicationJsonApiContentType,
    "token": token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.applyLeaveAPiId = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.applyLeave
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

handelRemoveSpace = (time: string): string => {
  return time.replace(' ', '');
};

handleTitleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
  this.setState({title:event.target.value,error:""})
};

handleClick = () => {
  this.setState({isAddTitle:!this.state.isAddTitle})
};

formatCustomDate = (date:any) => {
  if (!date || !(date instanceof Date)) {
    return ''; 
  }

  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
}

goToRecurrence = () => {
    this.setState({isRepeat:false,isRecurrence:false})
}

handleDayClick = (day: string) => {
  this.setState((prevState) => {
    const { activeDays } = prevState;

    if (activeDays.includes(day)) {
      return { activeDays: activeDays.filter((selectedDay) => selectedDay !== day) };
    } else {
      return { activeDays: [...activeDays, day]};
    }
  });
  this.setState({error:""})
};

  handleOccurrence = () => {
    this.setState({
      isOccurrence: !this.state.isOccurrence,
      showDropdown: false
    }, () => {
      if (this.state.isOccurrence) {
        this.setState({ endType: false })
      }
    });
  };

handleOccurrenceCountChange = (count: number) => {
  this.setState({
    occurrenceCount: count,
    showDropdown: false, 
  });
};

toggleDropdown = () => {
  this.setState((prevState) => ({
    showDropdown: !prevState.showDropdown, 
  }));
};

closeDropdown = () => {
  this.setState({ showDropdown: false }); 
};

formatDateToYYYYMMDD = (date: Date): string  => {
  const day: string = String(date.getDate()).padStart(2, '0');
  const month: string = String(date.getMonth() + 1).padStart(2, '0'); 
  const year: number = date.getFullYear();

  return `${year}-${month}-${day}`;
}

formatDateToDayAbbreviation(type:string) {
  const date = new Date(this.state.date);

  const dayAbbreviation = date.toLocaleString('en-US', { weekday: 'short' });

  const day = date.toLocaleString('en-US', { weekday: 'long' }).toLocaleLowerCase();

  const dayOfMonth = date.getDate();

  if (type === "date"){
      return `${dayAbbreviation} ${dayOfMonth}`;
  }

  if (type === "day"){
    return day;
}
}

responseLeaveApplied = (responseJson: any) => {
  this.setState({
    loading: false,
    errorMessage: responseJson.errors ? responseJson.errors : "",
    successMessage: responseJson.meta ? responseJson.meta.message : "",
    showSuccessMsg: false,
    isLeaveConfirmation: false,
  }, () => {
    this.apiHealthcarePersonnelAvailabilities()
  })
}

setCustomAvaibilityResponse = (responseJson:any) => {
  this.setState({
    loading: false,
    errorMessage: responseJson.errors ? responseJson.errors.message : "",
    successMessage: responseJson.meta ? responseJson.meta.message : "",
    slot:"",
    start_time:"",
    end_time:"",
    shiftTime:"",
    isOpen: false,
    endType: false,
    recurringType: responseJson?.data?.attributes?.custom_repeat_type !== null ? responseJson.data.attributes.custom_repeat_type.charAt(0).toUpperCase() + responseJson.data.attributes.custom_repeat_type.slice(1).toLowerCase() : "",
    isFullDay: false,
    selectedMonthType: "",
    isRecurrence: false,
    selectedType: "Does not repeat",
    isMonthly: false,
    isRepeat: false,
    selectedCustomType:"Every month",
    isUpdatedId:"",
    avaibilityPopup: false,
  },()=>{
    if(this.state.errorMessage===''){this.apiHealthcarePersonnelAvailabilities()}
  })
}

getAvaibilityResponse = (responseJson:any) => {
  const firstStartDate = this.formatTimeWithAMPM(new Date(responseJson.data.attributes.first_shift.data.attributes.start_time));
  const firstEndDate = this.formatTimeWithAMPM(new Date(responseJson.data.attributes.first_shift.data.attributes.end_time));
  const slot = parseInt(responseJson.data.attributes.second_shift.data.attributes.duration.replace("min", ""))
  const firstSlotTime = this.createTimeSlots(firstStartDate, firstEndDate, slot)

  const secondStartDate = this.formatTimeWithAMPM(new Date(responseJson.data.attributes.second_shift.data.attributes.start_time));
  const secondEndDate = this.formatTimeWithAMPM(new Date(responseJson.data.attributes.second_shift.data.attributes.end_time));
  const secondSlotTime = this.createTimeSlots(secondStartDate, secondEndDate, slot);
  this.setState({
    loading: false,
    firstSlotTime,
    secondSlotTime,
    firstStartDate,
    firstEndDate,
    secondStartDate,
    secondEndDate,
    slotDiff:slot,
    firstShiftMedicAvailabilityId: responseJson.data.attributes.first_shift.data.id,
    secondShiftMedicAvailabilityId: responseJson.data.attributes.second_shift.data.id,
    leaveDetails:responseJson.data.attributes.leave_deatils,
    firstSlotCustomMedicAvailability:responseJson.data.attributes.first_shift.data.attributes.custom_medic_availability,
    secondSlotCustomMedicAvailability:responseJson.data.attributes.second_shift.data.attributes.custom_medic_availability,
    errorMessage: responseJson.errors ? responseJson.errors.message : "",
  });         
}

handelResetLeave = async (id:number) => {
  const token = await getStorageData("authToken") as string;
  
  const header = {
    "Content-Type": configJSON.applicationJsonApiContentType,
    "token": token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.cancelLeaveAPIId = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.delteLeaveAPI}${id}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.deleteMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

responseCancelLeave = (responseJson: any) => {
  this.setState({
    loading: false,
    showSuccessMsg: false,
    isLeaveConfirmation: false,
    errorMessage: responseJson.errors ? responseJson.errors : "",
    successMessage: responseJson.meta ? responseJson.meta.message : "",
  }, () => {
    if(this.state.errorMessage===''){this.apiHealthcarePersonnelAvailabilities()}
  })
}
  // Customizable Area End
}